
<template>
  <div class="container-fluid">
      <ul class="navbar-nav h-100" id="navbar-nav">

        <li class="menu-title">
          <span data-key="t-dashboards"> {{ $t("t-dashboards") }}</span>
        </li>
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/shipping" >
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-shipping"> {{ $t("t-shipping") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/preview" >
            <i class="ri-dashboard-2-line"></i>
            <span data-key="t-preview"> {{ $t("t-preview") }}</span>
          </router-link>
        </li>

        <li class="menu-title">
          <span data-key="t-management">{{ $t("t-management") }}</span>
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link menu-link" to="/settings">
            <i class="ri-settings-5-line"></i>
            <span data-key="t-settings">{{ $t("t-settings") }}</span>
          </router-link>
        </li> -->
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/staff">
            <i class="ri-group-line"></i>
            <span data-key="t-staff">{{ $t("t-staff") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/wallets">
            <i class="ri-wallet-2-line"></i>
            <span data-key="t-wallets">{{ $t("t-wallets") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/nfts">
            <i class="ri-wallet-2-line"></i>
            <span data-key="t-nfts">{{ $t("t-nfts") }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link menu-link" to="/achievements">
            <i class="ri-coupon-3-line"></i>
            <span data-key="t-coupons">{{ $t("t-coupons") }}</span>
          </router-link>
        </li>
      </ul>
  </div>  
</template>

<script>
import { layoutComputed } from "@/state/helpers"

export default {
  components: {},
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    }
  },
  computed: {
    ...layoutComputed,
    layoutType: {
      get() {
        return this.$store ? this.$store.state.layout.layoutType : {} || {}
      },
    },
  },

  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
  },

  mounted() {
    if (document.querySelectorAll(".navbar-nav .collapse")) {
      let collapses = document.querySelectorAll(".navbar-nav .collapse")

      collapses.forEach((collapse) => {
        // Hide sibling collapses on `show.bs.collapse`
        collapse.addEventListener("show.bs.collapse", (e) => {
          e.stopPropagation()
          let closestCollapse = collapse.parentElement.closest(".collapse")
          if (closestCollapse) {
            let siblingCollapses =
              closestCollapse.querySelectorAll(".collapse")
            siblingCollapses.forEach((siblingCollapse) => {
              if (siblingCollapse.classList.contains("show")) {
                siblingCollapse.classList.remove("show")
              }
            })
          } else {
            let getSiblings = (elem) => {
              // Setup siblings array and get the first sibling
              let siblings = []
              let sibling = elem.parentNode.firstChild
              // Loop through each sibling and push to the array
              while (sibling) {
                if (sibling.nodeType === 1 && sibling !== elem) {
                  siblings.push(sibling)
                }
                sibling = sibling.nextSibling
              }
              return siblings
            }
            let siblings = getSiblings(collapse.parentElement)
            siblings.forEach((item) => {
              if (item.childNodes.length > 2)
                item.firstElementChild.setAttribute("aria-expanded", "false")
              let ids = item.querySelectorAll("*[id]")
              ids.forEach((item1) => {
                item1.classList.remove("show")
                if (item1.childNodes.length > 2) {
                  let val = item1.querySelectorAll("ul li a")

                  val.forEach((subitem) => {
                    if (subitem.hasAttribute("aria-expanded"))
                      subitem.setAttribute("aria-expanded", "false")
                  })
                }
              })
            })
          }
        })

        // Hide nested collapses on `hide.bs.collapse`
        collapse.addEventListener("hide.bs.collapse", (e) => {
          e.stopPropagation()
          let childCollapses = collapse.querySelectorAll(".collapse")
          childCollapses.forEach((childCollapse) => {
            let childCollapseInstance = childCollapse
            childCollapseInstance.hide()
          })
        })
      })
    }
  },

  methods: {
    onRoutechange(ele) {
      this.initActiveMenu(ele.path)
      if (document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop
        if (currentPosition > 500)
          if (this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300
      }
    },

    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#navbar-nav")) {
          let a = document.querySelector("#navbar-nav").querySelector('[href="' + ele + '"]')
          if (a) {
            a.classList.add("active")
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown")
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show")
              parentCollapseDiv.parentElement.children[0].classList.add("active")
              parentCollapseDiv.parentElement.children[0].setAttribute("aria-expanded", "true")
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement.closest(".collapse").classList.add("show")
                if (parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling)
                  parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.classList.add("active")
                const grandparent = parentCollapseDiv.parentElement.closest(".collapse").previousElementSibling.parentElement.closest(".collapse")
                if(grandparent && grandparent && grandparent.previousElementSibling){
                  grandparent.previousElementSibling.classList.add("active")
                  grandparent.classList.add("show")
                }
              }
            }
          }
        }
      }, 0)
    },
  },
}
</script>