<template>
  <div>
    <Vertical>
      <slot />
    </Vertical>
  </div>
</template>

<script>
import { layoutComputed } from "@/state/helpers";
import Vertical from "./vertical";

// We use the vertical layout only
// But this hub can be used to switch between
// Different ones withot affecting the views
export default {
  components: {
    Vertical
  },
  data() {
    return {};
  },
  computed: {
    ...layoutComputed,
  },
  mounted() {
  }
};
</script>
